import React from "react";
import first from "../../img/1.png";
import second from "../../img/2.png";
import third from "../../img/3a.png";
import bimg from "../../img/banner_img.svg";

import "./style.css";
import Reveal from "../Reveal";

function Home() {
  return (
    <div className="home_wrapper">
      <div className="banner_container">
        <div className="left_banner">
          <Reveal component={<h6>Prezado cliente,</h6>} delay={0.7} />

          <Reveal
            component={
              <h1>
                Estamos comprometidos em manter a segurança e eficiência dos
                seus e-mails no MAV Mail
              </h1>
            }
            delay={1.2}
          />

          <Reveal
            component={
              <p>
                Gostaríamos de informar sobre duas atualizações de segurança que
                estão por vir para reforçar ainda mais essa proteção.
              </p>
            }
            delay={1.6}
          />
        </div>
        <div className="right_banner">
          <Reveal component={<img src={bimg} alt="block"></img>} delay={1.4} />
        </div>
      </div>
      <div className="home_container">
        <div className="block_container">
          <Reveal
            component={<div className="topic">Atualização 1 </div>}
            delay={2}
          />
          <Reveal
            component={
              <h4>
                Bloqueio de envios de e-mails a partir de IPs fora do Brasil
              </h4>
            }
            delay={2}
          />
          <Reveal
            component={
              <p>
                Atualmente, para proteger suas comunicações, o MAV Mail permite
                que os administradores configurem o bloqueio de envio de e-mails
                a partir de IPs localizados fora do Brasil. Essa medida visa
                garantir que seus e-mails sejam enviados apenas de locais
                confiáveis, aumentando a segurança.
              </p>
            }
            delay={2}
          />
          <Reveal
            component={
              <p>
                No entanto, para tornar essa funcionalidade ainda mais eficaz e
                intuitiva, realizaremos uma alteração importante. A partir da
                próxima atualização, <strong>06/11/2023</strong>, todos os
                envios de IPs de fora do Brasil
                <strong>serão bloqueados por padrão</strong>, reforçando ainda
                mais a segurança das suas contas de e-mail.
              </p>
            }
          />
          <Reveal
            component={
              <p>
                Entendemos que situações especiais podem exigir o envio de
                e-mails de IPs internacionais, como viagens de negócios,
                servidores hospedados internacionalmente
                <strong>
                  (como sistemas e sites hospedados na Aws, Microsoft, Google,
                  entre outros)
                </strong>
                ou parcerias globais. Para garantir flexibilidade, incluiremos
                uma nova opção na aba"Zona de Segurança" do filtro. Os
                administradores de domínio poderão habilitar o envio de e-mails
                a partir de IPs de fora do país quando necessário, garantindo
                operações globais sem interrupções. Essa opção é configurada por
                filtro, logo, o filtro com essa configuração deve estar aplicado
                apenas para a(s) conta(s) que necessitam dessa configuração
                ativa, caso contrário, não terá aumento na segurança do domínio.
              </p>
            }
          />
          <Reveal
            component={
              <p>
                <strong>Atualmente:</strong>
              </p>
            }
          />
          <Reveal component={<img src={first} alt="block"></img>} />
          <Reveal
            component={
              <p>
                <strong>A partir de 06/11:</strong>
              </p>
            }
          />
          <Reveal component={<img src={third} alt="block"></img>} />
          <Reveal component={<div className="topic">Atualização 2 </div>} />
          <Reveal
            component={
              <h4>Bloqueio de envio de contas diferentes da autenticada </h4>
            }
          />

          <Reveal
            component={
              <p>
                Atualmente, seguindo as regras do protocolo SMTP, é permitido
                autenticar-se com um usuário de e-mail, mas enviar mensagens
                usando o <strong>"Mail From"</strong> de outro usuário do mesmo
                domínio.
              </p>
            }
          />
          <Reveal
            component={
              <p>
                O <strong>"Mail From"</strong> é o endereço que aparece como o
                remetente de um e-mail e é parte do protocolo SMTP. Essa prática
                é uma fonte potencial de abusos e phishing.
              </p>
            }
          />
          <Reveal
            component={
              <p>
                No nosso Painel de Controle, existe uma opção que permite
                bloquear essa situação, garantindo que a conta só possa enviar
                mensagens como ela mesma. Anteriormente, essa opção vinha
                desabilitada por padrão.
              </p>
            }
          />
          <Reveal
            component={
              <p>
                A partir de <strong>06/11/2023</strong>, esta funcionalidade
                será habilitada por padrão para novas contas criadas, o que
                significa que elas não poderão mais enviar mensagens usando um
                <strong>"Mail From"</strong> diferente do seu próprio endereço.
                Caso seja necessário permitir essa funcionalidade, os
                administradores do domínio deverão desmarcar a opção
                <strong>
                  "Bloquear envio de conta diferente da autenticada"
                </strong>
                no Painel de Controle.
              </p>
            }
          />

          <Reveal
            component={
              <p>
                Essa opção é útil para contas que são configuradas em sistemas
                que precisam realizar essa alteração de "Mail from". Caso deseje
                já realizar o bloqueio para todas as contas atuais e aumentar a
                segurança, um contato pode ser realizado com o suporte para que
                alteração seja realizada de forma fácil.
              </p>
            }
          />

          <Reveal component={<img src={second} alt="block"></img>} />
          <Reveal
            component={
              <p>
                Estas atualizações visam simplificar a configuração de
                segurança, garantindo uma experiência de e-mail mais segura e
                eficiente. Estamos à disposição para esclarecer dúvidas e
                fornecer assistência durante essa transição.
              </p>
            }
          />
          <Reveal
            component={
              <div className="action_button">
                <strong>
                  <a href="https://seguranca.mav.com.br/">Clique e saiba</a>
                </strong>{" "}
                como deixar sua conta de e-mail mais segura!
              </div>
            }
          />

          <Reveal
            component={<p>Agradecemos pela confiança em nossa plataforma. </p>}
          />
          <Reveal
            component={
              <>
                <p>
                  <strong>Atenciosamente,</strong>
                </p>
                <p>
                  <strong>Equipe MAV Tecnologia</strong>
                </p>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
