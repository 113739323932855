import React from "react";
import mav from "../../img/mav.svg";
import "./style.css";

function Menu() {
  return (
    <div className="menu_wrapper">
      <div className="menu_container">
        <div className="logo">
          <img src={mav} alt="mav"></img>
        </div>
      </div>
    </div>
  );
}

export default Menu;
