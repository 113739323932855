import React from 'react';

import './style.css';

function Footer() {
  return <div className='footer_wrapper'>
    <div className='footer_container'>
        <div className='rights'>Todos os direitos reservados @2019 – MAV Tecnologia</div>

    </div>

  </div>;
}

export default Footer;