import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Menu from "./components/Menu";

function App() {
  return (
    <div className="App">
      <Menu/>
      <Home />
      <Footer/>
    </div>
  );
}

export default App;
